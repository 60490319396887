import Vue from 'vue'
import App from './App.vue'
import router from './router'


import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents)

import VueMasonry from 'vue-masonry-css'
Vue.use(VueMasonry);


//start honeybadger
/*
import HoneybadgerVue from '@honeybadger-io/vue'

const config = {
  apiKey: '4ee32fdc',
  environment: 'production'
}

Vue.use(HoneybadgerVue, config)
//end honeybadger
*/





Vue.config.productionTip = false

new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
