<template>
    <div class="bg-gray-200">



      
        <HomeNavigation><slot></slot></HomeNavigation>

        

        

        <div id='wallpaper' class="md:pt-16 md:pt-8 pt-4 flex items-center justify-center pb-16" style='background-color: #EDF4FE'>
            <div class="max-w-5xl w-full mx-2">
                <div class="grid grid-cols-1 lg:grid-cols-2">
                    <div  class="mx-2 md:mx-6">
                        <h1  class="hero font-bold text-4xl text-center text-gray-700">Easily collect photos and videos from a group</h1>
                       
                        
                        <!--<h2 class="text-4xl text-center font-extrabold" id="typeOfEvent">{{typeOfEvent}}</h2>-->

                       

                        <h2  class="text-center text-xl mt-6 mb-4 mx-0 md:mx-6"><span class='px-1 rounded bg-yellow-200'>Perfect for sharing wedding photos.</span>  No apps or signups required for your group.</h2>
                        

                        
                        <div class="text-center">
                            <button id='mainCTA' class="align-middle mb-3 mr-1 bg-green-500 hover:bg-green-600 hover:text-white text-white py-4  pl-4 pr-6 rounded text-2xl">
                                 <!--<a :href='dataStore.isAuthenticated ? "/createPicpack":"/signin"'>&#11088; Create Your PicPack</a>-->
                                  <a  href='/createPicpack'>&#11088; <span class='font-bold'>Create a PicPack</span></a>
                                  
                                 
                            </button>

                            <!--<button @click="setCookie" class="mb-2 mr-1 bg-blue-500 hover:bg-blue-600 border border-blue-600 text-white font-bold py-2 px-4 rounded text-2xl">
                                Set Cookie
                            </button>-->

                           <!-- <button @click="routeSecondCTA" class="bg-blue-500 ml-1 mr-1 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-2xl">
                                Your events
                            </button>-->
                        </div>


                        <!--
                        <div v-if='dataStore.isAuthenticated' class="mt-2 text-center">
                            <button id='mainCTA' class="align-middle mb-2 mr-1 bg-blue-500 hover:bg-blue-600 hover:text-white text-white font-bold py-4  pl-4 pr-6 rounded text-2xl">
                                 <a  href='/picpacks'>&#128247; View Your PicPacks</a>
                                
                            </button>

                           
                        </div>-->

                         <div class="mt-0 mb-6 lg:mb-0 md:mt-2 grid grid-cols-1 md:grid-cols-1 text-gray-400 text-center">

                             <div ><v-icon class="mr-2 text-gray-400" name="check"></v-icon>100 photos and videos for free</div>
                            
                              <!--<div><v-icon class="mr-2 text-gray-600" name="check"></v-icon></div>-->
                             <div><v-icon class="mr-2  text-gray-400" name="check"></v-icon>No credit card required</div>
                           
                             

                         </div>


                          <div class="hidden bg-blue-100 mx-auto max-w-sm  text-center mt-6 border-blue-100 border border-2 rounded mb-8 pt-2">

                               <h2  class='mb-1 font-bold'>Did you get invited to a PicPack?</h2>
                              

                            <div class="pb-4">

                               

                                <form onsubmit="return false;">
                               <label for="quickcode">
                                <input
                                    name="quickcodeName"
                                    v-on:keyup.enter="quickcodeRoute"
                                    id="quickcode"
                                    :tokens="hexTokens"
                                    class="uppercase tracking-wide w-3/4 ml-2 text-center py-2 text-xl shadow appearance-none border rounded px-3 text-gray-700 focus:outline-none focus:shadow-outline"
                                    v-model="quickcode"
                                    placeholder="Enter secret code"
                                />
                                </label>

                                <input type="hidden" id='comment' v-model='comment' name="comment" value="" aria-hidden="true">
                                
                               

                                <button @click="quickcodeRoute" id='quickCodeButton' class="ml-2 text-center bg-blue-500 hover:bg-blue-700 font-bold py-2 px-4 rounded text-lg">
                                    <span class="text-white px-1" v-if="quickcoding"><v-icon class="text-white text-xl" name="sync" spin></v-icon></span>
                                    <span class="text-white" v-else>Go</span>
                                </button>
                                 </form>

                            </div>
                        </div>


                    </div>

                    

                
                    <div  class='mx-auto h-full flex flex-wrap content-center'>
                    <div class='relative text-center mx-4'>
                        <img  fetchpriority="high" alt='wedding group' width="1024" height="682" class="mt-0 md:mt-0 object-center rounded-2xl" src="https://ik.imagekit.io/iwyeviorj/weddingHero2.webp" />
                        <button style="top: 50%; left: 50%; transform: translate(-50%, -50%);" class="absolute align-middle  opacity-90 bg-yellow-400 hover:bg-yellow-500 hover:text-white text-white font-bold py-4  pl-6 pr-6 rounded text-xl">
                            <a  href='https://picpack.app/welcome-to-picpack'>See this PicPack</a>
                        </button>
                        
                    </div>
                    </div>





                   




                </div>
            </div>
        </div>



        <div class="pt-12 md:pt-16 pb-8 md:pb-16 flex items-center justify-center border border-l-0 border-r-0 border-t-1 border-b-1 border-gray-300 bg-yellow-300">
            <div class="max-w-5xl w-full mx-6">
                <div class="grid grid-cols-1 md:grid-cols-3">
                    
                    <div  class="py-2 px-4 md:px-8 text-center mb-4">
                         <h2 class="text-5xl mb-2">&#127914;</h2>
                        <h3 class="text-3xl font-bold mb-2">For Everyone</h3>

                        <p class='text-lg'>PicPack is the best way to collect photos and videos between people who are not connected on social media sites like Facebook and Instagram.</p>
                    </div>

                    
                    
                    
                    <div  class="py-2 px-4 md:px-8 text-center mb-4">
                        <h2 class="text-5xl mb-2">&#128106;</h2>
                        <h3 class="text-3xl font-bold mb-2">Easy To Share</h3>

                        <p class='text-lg'>No app required!  Custom links and QR codes so your group can collect photos and videos with just a few clicks. Share via text, Facebook, Instagram, email or a printed flyer.</p>
                    </div>

                   


                    <div  class="py-2 px-4 md:px-8 text-center mb-4">
                        <h2 class="text-5xl mb-2">&#128272;</h2>
                        <h3 class="text-3xl font-bold mb-2">Safe & Secure</h3>

                        <p class='text-lg'>Decide who to share your PicPack link and secret code with. Approve photos and videos before publishing.  Add a password for even greater privacy.</p>
                    </div>

                   

                     

                    
                </div>
            </div>
        </div>

        <div class="pt-8 flex items-center justify-center pb-8" style='background-color: #EDF4FE'>
            <div class="max-w-4xl w-full">
                <div class="px-4 text-center mb-4">
                    <h2 class="text-3xl mt-2 md:mt-8 mb-6"><span class='hero'>Groups</span>  &#10084; <span class='hero'>PicPack</span></h2>
                     

                    <p  class="text-2xl mb-2 mx-6">Lots of people and organizations are already using PicPack to collect photos and videos</p>
                     

                    <div class="grid grid-cols-1 lg:grid-cols-3 my-6 md:my-10 mb-10 ">
                        <div  class="mt-4 md:mt-0 font-bold mx-4 text-5xl text-blue-500 md:border-r-2 mb-2 md:mb-4">
                            <h3>74,223</h3>
                            <div class='text-xl text-gray-600'>images</div>
                        </div>
                         

                        <div class="mt-4 md:mt-0  font-bold mx-4 text-5xl text-blue-500 md:border-r-2 mb-2 md:mb-4">
                            <h3>6,593</h3>
                             <div class='text-xl text-gray-600'>videos</div>
                        </div>

                        <div  class="mt-4 md:mt-0  font-bold mx-4 text-5xl text-blue-500 mb-2 md:mb-4">
                            <h3>391</h3>
                             <div class='text-xl text-gray-600'>events</div>
                        </div>
                        
                   

                    </div>


                     
                    <div  class='mx-auto mt-8' id="cf4a">
                        <img alt="competition" class="bg-white rounded shadow p-4 bottom" src="https://assets.picpack.app/assets/images/competitionCrop2.webp" />
                        <img alt="football" class="bg-white rounded shadow p-4  top " src="https://assets.picpack.app/assets/images/flagFootballCrop2.webp" />
                        <img alt="robot" class="bg-white rounded shadow p-4 bottom" src="https://assets.picpack.app/assets/images/robotCrop2.webp" />
                        <img alt="wedding" class="bg-white rounded shadow p-4  top " src="https://assets.picpack.app/assets/images/weddingCrop2.webp" />
                    </div>


                   
                    

                <div class='text-lg mt-10 mx-2 md:mx-4'>

                    <h2 ><span class='font-bold'>Collect photos and videos for:</span>  <a target='_blank' href='/collect-wedding-photos-from-guests.html'>Weddings</a>, Virtual Teams, <a target='_blank' href='/memorials-for-loved-ones.html'>Memorials</a>, Team Sports, Church Groups, Craft Shows, School Events, Fund Raisers, Recitals, Trade Shows, Field Inspections, Summer Camps, <a target='_blank' href='/family-reunion-photos.html'>Family Reunions</a>, Tournaments, Meetups, Clubs, Conferences, Concerts, Fairs, Birthday Parties, Galas, Charity Events, and Group Outings.</h2>
                   

                </div>

                <div  class='rounded bg-blue-100 border p-10 text-md mt-6 mx-2 md:mx-4 text-left'>

                    <h2 class='font-bold'>Collect photos the smart way</h2>

                    <p class='my-3'>For groups at events like weddings, parties, or <a href='/virtual-memorials.html'>memorials</a>, it should be easy to collect photos and videos.  For many reasons, this isn’t as easy to do as you think. While thousands of photos and videos can be stored on your smartphone, collecting even fifty pictures at your next event is surprisingly difficult.</p>

                    <p class='my-3'>Services like Google Photos and Cluster have made things a lot easier, but if you ask your random group how they would gather photos from each other, you’ll most likely get ten different  answers. Not everyone is connected on the same social network.  Collecting photos is even more difficult for a company looking to collect photos from different groups or team members working remotely due to Covid-19.  Collecting photos and videos from a diverse range of customers is even more difficult.</p> 

                    <p class='my-3'>You are probably at Picpack because you need to collect photos from a group at an event. Maybe you are helping a bride at a small wedding, or collecting photos for a surprise birthday party.  You want to gather the best of all of the photos and videos your group took from their fancy phones.</p>

                    <p class='my-3'>Maybe you have a concern around security and privacy. Maybe you want to approve all photos and videos before sharing with your group.  The app you need may need to handle videos in addition to photos. You may want to preserve the original resolution of uploaded photos and videos . Your group may not want to be bothered downloading an app or requiring them to sign up with an email and password.   Maybe you want to ask users to supply an email address and provide a caption before uploading their photos and videos.</p>

                     <p class='my-3'>The good news is PicPack can do all of this for you.  Designed to be easy to use with many packages available, PicPack is the best photo collection app capturing and sharing photos and videos with your group, no matter the size.</p>


                </div>

               


                




                    



                </div>
            </div>
        </div>

        <div class="pt-8 flex items-center justify-center bg-white pb-8">
            <div class="max-w-4xl w-full mx-1">
                <div class='text-center'>

                   


                    <div class='mt-8 mb-8 mx-4 '>

                         <h2 class="text-3xl mt-2 md:mt-8 mb-6"><span class='hero'>Testimonials</span></h2>

                    <div class='my-6 text-left flex'>

                        <div class='text-center w-1/4'><img alt='Jen' class='mx-auto w-24' src='https://assets.picpack.app/assets/images/testimonial12.webp' /></div>
                        
                         <div  class='ml-3 w-3/4 my-auto'>"Myself and some friends added photos and videos to our PicPack for a destination wedding we attended. I was able to simply push a button and create a PicPack that I sent to the wedding guests via text.  We collected hundreds of great photos and videos in a matter of minutes!" - <span class='font-bold italic'>Jen W</span></div>
                        
                    </div>

                    <div class='my-6 text-left flex'>

                         <div class='mx-auto w-1/4'><img alt='Francis' class='mx-auto w-24' src='https://assets.picpack.app/assets/images/testimonial32.webp' /></div>

                         <div  class='ml-3 w-3/4 my-auto'>"PicPack has become a great way for the volleyball team I coach to collect photos and videos from our games — it's essentially a group photo album for my team where parents and athletes can quickly and easily share photos and videos." - <span class='font-bold italic'>Francis T</span></div>
                       
                    </div>

                    <div class='my-6  text-left flex'>

                         <div class='text-left w-1/4'><img alt='Rachael' class='mx-auto w-24' src='https://assets.picpack.app/assets/images/testimonial22.webp' /></div>
                      
                      <div  class='ml-3 w-3/4 my-auto'>"PicPack let me create a theme, and then asked my family to build a heartfelt set of messages about our great uncle who recently passed away. When we couldn't all attend the funeral, Picpack was a great help.  Everyone loved the fact that they could add photos, videos and notes about our uncle.  PicPack was a great way to celebrate his life!" - <span class='font-bold italic'>Rachael M</span></div>
                      
                    </div>




                </div>

                <hr class='mx-6 my-16' />


                    <div class='text-7xl'>

                     &#128522;

                    </div>

                   

                    <h3  class='mt-6 text-4xl font-bold'>Collect Photos Now</h3>
                     

                    <h4  class='mt-1 text-xl text-gray-600'>Your first PicPack is free with up to 100 images and videos — no credit card required</h4>
                    
                     <!--<h4 class='mt-1 text-xl text-gray-600'>Free unlimited photos and videos — no credit card required</h4>-->
                    <button  class="mt-4 align-middle mb-2 mr-1 bg-green-500 hover:bg-green-600 hover:text-white text-white py-4  pl-4 pr-6 rounded text-2xl">
                                 <a  href='/createPicpack'>&#11088; Create a PicPack</a>
                                 
                    
                    </button>
                </div>
            </div>
        </div>

        <Footer><slot></slot></Footer>
    </div>
</template>


<script>

/*

import firebase from '@firebase/app'

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import "firebase/storage";

import { mask } from 'vue-the-mask'

*/


import HomeNavigation from '@/components/HomeNavigation.vue'
import Footer from '@/components/Footer.vue'

/*
import { store } from "../store/store.js";
*/


import '@/assets/styles/index.css'


/*
import  'vue-awesome/icons/sync'
import  'vue-awesome/icons/check'
import  'vue-awesome/icons/mouse-pointer'
import Icon from 'vue-awesome/components/Icon'
*/



export default {


    name: 'home',

    directives: {
        //mask
    },



     components: {
        
            HomeNavigation,
            Footer,
            //'v-icon' : Icon
        
    },


    //======================================================================================
    //======================================================================================
    data: function() {
        return {

           
     
            hexTokens: {
            F: {
                pattern: /[0-9A-F]/,
                transform: v => v.toLocaleUpperCase()
            }
            },
      
    

           
            
          //  dataStore: store,
            typeOfEventArray : ['Weddings','Zoom Meetings','School Carnivals','Soccer games','Conferences','Team Outings'],
            typeOfEvent : "Weddings",
            quickcode : '',
            quickcoding : false,
            comment : '',
            
            





        }

    },




    //======================================================================================
    //======================================================================================
    mounted() {

        

        console.log('mounted')
        var vue = this

       // this.$honeybadger.notify('Hello from Vue')


        $("#slideshow > div:gt(0)").hide();

        setInterval(function() { 
        $('#slideshow > div:first')
            .fadeOut(2000)
            .next()
            .fadeIn(2000)
            .end()
            .appendTo('#slideshow');
        },  5000);

        var divHeight = $('.block').height();
        $('#slideshow').css({'height' : divHeight});

        /*

         store.checkForAuthentication().then((value) => {

               if ( store.isAuthenticated ) {

                     store.generatePortalLink()

               }



        })
        */

        console.log('mounted')




    },


    //=======================================================================================
    //=======================================================================================
    computed: {


    dummyComputed : function() {

      return "dummy"



    }




    },

    updated: function() {

    },




    watch: {

        watchedField: function(val) {




        }

    },




    created() {

      document.title = 'Collect photos and videos and share with your group'
        

        var vue = this
       // store.vue = this;

       // store.establishEnvironment()

       // if ( store.website === "a" ) {

          


       // } else {

         //     document.title = 'The 2-in-1 Greeting and Gift Card'

       // }

        console.log('\n\n\n')
   
        console.log('__________.__      __________                __    \r\n\\______   \\__| ____\\______   \\_____    ____ |  | __\r\n |     ___\/  |\/ ___\\|     ___\/\\__  \\ _\/ ___\\|  |\/ \/\r\n |    |   |  \\  \\___|    |     \/ __ \\\\  \\___|    < \r\n |____|   |__|\\___  >____|    (____  \/\\___  >__|_ \\\r\n                  \\\/               \\\/     \\\/     \\\/')

        
       
        console.log('%cDevs - Hit me up on %cjason@picpack.app', 'font-weight:bold', 'color: red')
        console.log('Built on Google Cloud + Firebase + Vue')
        
        console.log('\n\n\n')

        
       // firebase.initializeApp(store.firebaseConfig);
       // store.analytics = firebase.analytics();
        
                                   



    },




    //===============================================================================
    //METHODS
    //===============================================================================
    methods: {




        async quickcodeRoute() {

           
           console.log('okokok')

            var vue = this
            vue.quickcoding = true

          

            if ( vue.comment === '' && vue.quickcode.length === store.picpackCodeLength ) {

               // alert(vue.quickcode.toUpperCase())

           

            const eventDoc = store.firestore.collection('events').where("quickcode", "==", vue.quickcode.toUpperCase())
                
            const eventDocs = await eventDoc.get();

             console.log(eventDocs)

             if ( eventDocs.docs.length ) {
                
            if (!eventDocs.docs[0].exists) {

                console.log('couldnt find quickcode so we are good')
                 vue.quickcoding = false

                 var quickcodeElement = document.getElementById('quickcode')
                 vue.quickcode = "";
                 quickcodeElement.placeholder = "Hmmmmm?"
                



                //uniqueCode = true
            } else {


                 store.setCookie('RecipientCookie', "giftycard")
                 store.setCookie('CookieCode', vue.quickcode.toUpperCase())

                console.log('found quickcode so keep looking')
                window.location = "/" + eventDocs.docs[0].id
                
            }


             } else {

                 console.log('couldnt find quickcode so we are good')
                 vue.quickcoding = false

                 var quickcodeElement = document.getElementById('quickcode')
                 vue.quickcode = "";
                 quickcodeElement.placeholder = "Hmmmmm?"



             }


            } else {

                console.log('wierd')
                 vue.quickcoding = false

            }


        },


       routeMainCTA : function() {

           

            if ( store.isAuthenticated ) {

                window.location.href = '/createPicpack'


            } else {

                 window.location.href = '/signin'



            }
 

        


        },



          routeSecondCTA : function() {


              

            if ( store.isAuthenticated ) {

                window.location.href = '/picpacks'


            } else {

                 window.location.href = '/signup'



            }
 

        


        },





      




    }




}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.hero { font-family: 'Lora', serif; }





@-webkit-keyframes cf4FadeInOut {
 0% {
   opacity:1;
 }
 17% {
   opacity:1;
 }
 25% {
   opacity:0;
 }
 92% {
   opacity:0;
 }
 100% {
   opacity:1;
 }
}

@-moz-keyframes cf4FadeInOut {
 0% {
   opacity:1;
 }
 17% {
   opacity:1;
 }
 25% {
   opacity:0;
 }
 92% {
   opacity:0;
 }
 100% {
   opacity:1;
 }
}

@-o-keyframes cf4FadeInOut {
 0% {
   opacity:1;
 }
 17% {
   opacity:1;
 }
 25% {
   opacity:0;
 }
 92% {
   opacity:0;
 }
 100% {
   opacity:1;
 }
}

@keyframes cf4FadeInOut {
 0% {
   opacity:1;
 }
 17% {
   opacity:1;
 }
 25% {
   opacity:0;
 }
 92% {
   opacity:0;
 }
 100% {
   opacity:1;
 }
}

@media (min-width:320px)  { 

#cf4a {
  position:relative;
  height:205px;
  width:300px;

}


 }
@media (min-width:480px)  {

#cf4a {
  position:relative;
  height:310px;
  width:450px;

}


}




#cf4a img {
  position:absolute;
  left:0;
}

#cf4a img {
  -webkit-animation-name: cf4FadeInOut;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 16s;

  -moz-animation-name: cf4FadeInOut;
  -moz-animation-timing-function: ease-in-out;
  -moz-animation-iteration-count: infinite;
  -moz-animation-duration: 16s;

  -o-animation-name: cf4FadeInOut;
  -o-animation-timing-function: ease-in-out;
  -o-animation-iteration-count: infinite;
  -o-animation-duration: 16s;

  animation-name: cf4FadeInOut;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 16s;
}
#cf4a img:nth-of-type(1) {
  -webkit-animation-delay: 12s;
  -moz-animation-delay: 12s;
  -o-animation-delay: 12s;
  animation-delay: 12s;
}
#cf4a img:nth-of-type(2) {
  -webkit-animation-delay: 8s;
  -moz-animation-delay: 8s;
  -o-animation-delay: 8s;
  animation-delay: 8s;
}
#cf4a img:nth-of-type(3) {
  -webkit-animation-delay: 4s;
  -moz-animation-delay: 4s;
  -o-animation-delay: 4s;
  animation-delay: 4s;
}
#cf4a img:nth-of-type(4) {
  -webkit-animation-delay: 0;
  -moz-animation-delay: 0;
  -o-animation-delay: 0;
  animation-delay: 0;
}










.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}


input:focus::-webkit-input-placeholder 
{
    color: transparent;
}




.cryptedmail:after {
  content: attr(data-name) "@" attr(data-domain) "." attr(data-tld); 
}

*:focus {
    outline: none;
}


   
  
</style>

