<template>
  <div class='family15' id="app">
    
    <router-view/>
  </div>
</template>


<script>

export default {

watch: {
      '$route' (to, from) {
        //document.title = to.meta.title || to.params.articleID
        //document.title = "Collect "
      }
    }

}


</script>

<style scoped>


</style>