<template>

 
<!-- start footer -->
    <footer class="bg-gray-900 text-white py-10 lg:py-14">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex items-center">


                <div v-if='dataStore.website === "a"' class='w-1/3 text-left mr-6'>
                    <h3 class="font-bold text-2xl text-white">PICPACK</h3>
                    <p class="font-bold text-md text-white">Collect and share photos and videos</p>
                    <p class="text-md text-white">Austin, TX</p>
                    <p class="text-md text-white"><span class='text-yellow-400'>Kaiva LLC</span></p>
                    <!--<hr class='bg-gray-400 text-gray-200 mt-4 ml-20 mr-20' />-->
                </div>

                <div v-else class='w-1/3 text-left mr-6'>
                    <h3 class="font-bold text-2xl text-white">Giftycard</h3>
                    <p class="font-bold text-md text-white">2-in-1 Greeting & Gift Card</p>
                    <p class="text-md text-white">Austin, TX</p>
                    <p class="text-md text-white"><span class='text-yellow-400'>Kaiva LLC</span></p>
                    <!--<hr class='bg-gray-400 text-gray-200 mt-4 ml-20 mr-20' />-->
                </div>

                

                <div class='w-1/3 text-left'>
                    
                    <ul class="">
                        <li class="mt-2"><a href="/articles" title="" class="opacity-75 hover:opacity-100">Articles</a></li>
                         <li class="mt-2"><a href="/FAQ" title="" class="opacity-75 hover:opacity-100">FAQ</a></li>
                        <li class="mt-2"><a href="/about" title="" class="opacity-75 hover:opacity-100">About</a></li>
                        <li class="mt-2"><a href="/contact" title="" class="opacity-75 hover:opacity-100">Contact</a></li>
                        
                        
                        <!--<li class="mt-2"><a href="/presskit" title="" class="opacity-75 hover:opacity-100">Press Kit</a></li>-->
                    </ul>
                </div>

                 <div class='w-1/3 text-left '>
                    
                    <ul class="">
                        
                        <li class="mt-2"><a :href="dataStore.website === 'a' ? '/pricing' : '/giftycard-pricing'" title="" class="opacity-75 hover:opacity-100">Pricing</a></li>
                         <li v-if='dataStore.website === "a"' class="mt-2"><a href="/refunds" title="" class="opacity-75 hover:opacity-100">Fair Refunds</a></li>
                          <li v-else class="mt-2"><a href="/giftcards" title="" class="opacity-75 hover:opacity-100">Gift Cards</a></li>
                        
                        <li class="mt-2"><a href="/privacy" title="" class="opacity-75 hover:opacity-100">Privacy</a></li>
                        <li class="mt-2"><a href="/terms" title="" class="opacity-75 hover:opacity-100">Terms</a></li>
                       
                    </ul>
                </div>

                
            </div>

            
        </div>
    </footer>
  


</template>

<script>

  import { store } from "../store/store.js";
 

  export default {


       data: function() {
        return {

             dataStore: store,



        }}

   
   }

    




</script>

