<template>

 

     <nav id='navigationContainer' style='background-color: #EDF4FE' class="flex  pt-2 py-1 justify-between flex-wrap pl-4 pr-3 w-full z-10 top-0">

		<div class="flex items-center flex-shrink-0 text-white mr-6 p-1">
			
				<a aria-label="PicPack Home Page" v-if='dataStore.website === "a"' class='text-xl text-black inline-flex items-center' :href='dataStore.isAuthenticated ? "/picpacks" : "/"'><img alt='PicPack Logo' src='https://assets.picpack.app/assets/images/newLogo3.png' /></a>
                <a v-else class='text-xl text-black inline-flex items-center' :href='dataStore.isAuthenticated ? "/giftycards" : "/"'>&#127873; Giftycard</a>
		</div>

		<div class="inline-flex items-center items-center flex-shrink-0 lg:hidden">
            
			<button v-on:click="expandMenu" id="nav-toggle" class="fill-current p-2 border rounded text-black border-black">
				<svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
			</button>
           
		</div>

		<div class="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block lg:pt-0" id="nav-content">
			<ul class="list-reset lg:flex justify-end flex-1 items-center">

                <li v-if='dataStore.isAuthenticated' class="mr-3">
					<a v-if='dataStore.website === "a"' class="inline-block  no-underline text-black py-2 px-4 font-bold " href="/picpacks">Your PicPacks</a>
                    <a v-else class="inline-block  no-underline text-black py-2 px-4 font-bold " href="/giftycards">Your Giftycards</a>
				</li>
                

                <li class="mr-3">
					<a v-if='dataStore.website === "a"' class="inline-block py-2 px-4 text-black no-underline" href="/articles">Articles</a>
				</li>

                <li class="mr-3">
					<a class="inline-block py-2 px-4 text-black no-underline" href="/about">About</a>
				</li>



				<li class="mr-3">
					<a class="inline-block py-2 px-4 text-black no-underline" href="/FAQ">FAQ</a>
				</li>

                
                <li v-if='dataStore.website === "a"' class="mr-3">
					<a class="inline-block  no-underline text-black py-2 px-4" href="/pricing">Pricing</a>
				</li>

                 <li v-else class="mr-3">
					<a class="inline-block  no-underline text-black py-2 px-4" href="/giftycard-pricing">Pricing</a>
				</li>

                

                <!-- if this user has a stripe subscription account lets show it to them -->
                <li v-if='dataStore.website === "a" && dataStore.isAuthenticated && dataStore.stripeID !== "" && dataStore.plan !== 2' class="mr-3">
                    
                     <a href="javascript:;" v-if='dataStore.portalLoading === true' class="inline-block py-2 px-4 text-black no-underline"><v-icon name="sync" spin/></a>
                     <a :href="dataStore.portalLink" v-if='dataStore.portalLoading === false' class="inline-block py-2 px-4 text-black no-underline">Account</a>

				</li>
				
				<li class="mr-3">
					 <a v-if='dataStore.isAuthenticated' href="javascript:;" v-on:click='dataStore.logout' class="inline-block py-2 px-4 text-black no-underline">Logout</a>
                     <a v-else href="/login" class="inline-block  no-underline text-black py-2 px-4">Sign In</a>
				</li>
				
			</ul>
		</div>
	</nav>
  


</template>

<script>

  import { store } from "../store/store.js";

 import  'vue-awesome/icons/sync'
 import Icon from 'vue-awesome/components/Icon'
 

  export default {

      components: {

       
        "v-icon": Icon
       
       
  },


     methods : {

          expandMenu : function() {


              document.getElementById("nav-content").classList.toggle("hidden");

          }



      },


      	data: function() {
			return {
               
                dataStore: store,
                expanded : false
                
            }

      },

   
   }

    




</script>


<style scoped>

*:focus {
		outline: none;
	}

	


</style>


