import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    //meta: {
        //auth: false,
        //title: 'Giftycard - Show your appreciation',
       //description : 'PicPack is the easiest way to collect photos and videos and share with your group.  Perfect for weddings, meetups, tournaments, and memorials.'
     // },
      component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },

  {
    path: '/photo-collection',
    name: 'Home',
    meta: {
        auth: false,
        title: 'Photo collection app',
        description : 'PicPack is the easiest way to collect photos and videos and share with your group.  Perfect for weddings, meetups, tournaments, and memorials.'
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/PhotoCollection.vue')
  },


  {
    path: '/signin',
    name: 'Signup',
    meta: {
        auth: false,
        title: 'Sign In',
        description : 'Sign In'
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/Signup.vue')
    
  
  },


  {
    path: '/login',
    name: 'Login',
    meta: {
        auth: false,
        title: 'Login',
        description : ''
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/Signup.vue')
    
  
  },

  {
    path: '/pricing',
    name: 'Pricing',
    meta: {
        auth: false,
        title: 'Pricing',
        description : 'Details about the pricing for PicPack'
      },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Pricing.vue')
    }
  },

 
  
  {
    path: '/about',
    name: 'About',
    meta: {
        auth: false,
        title: 'About',
        description : 'A page about the founder'
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    
  
  },

  {
    path: '/FAQ',
    name: 'FAQ',
    meta: {
        auth: false,
        title: 'FAQ',
        description : 'Frequently asked questions'
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/FAQ.vue')
    
  
  },

  


  {
    path: '/forgot',
    name: 'Forgot',
    meta: {
        auth: false,
        title: 'Forgot My Password',
        description : 'Forgot my password page'
      },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Forgot.vue')
    }
  },

  {
    path: '/usermgmt',
    name: 'Reset',
    meta: {
        auth: false,
        title: 'User Management',
        description : 'User Management'
      },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Reset.vue')
    }
  },

 

  {
    path: '/contact',
    name: 'Contact',
    meta: {
        auth: false,
        title: 'Contact',
        description : 'Contact the founder'
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/Support.vue')
    
  
  },

 
  {
    path: '/privacy',
    name: 'Privacy',
    meta: {
        auth: false,
        title: 'Privacy Policy',
        description : 'The privacy policy for PicPack'
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/Privacy.vue')
    
  
  },
  {
    path: '/terms',
    name: 'Terms',
    meta: {
        auth: false,
        title: 'Terms of Service',
        description : 'The terms of service for PicPack'
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/Terms.vue')
    
  
  },
  {
    path: '/refunds',
    name: 'Refunds',
    meta: {
        auth: false,
        title: 'Fair Refund Policy',
        description : 'Our policy on issuing refunds'
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/Refunds.vue')
    
  
  },

  {
    path: '/createPicpack',
    name: 'CreatePicPack',
    meta: {
        auth: true,
        title: 'Create a New PicPack',
        description : ''
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/CreateEvent.vue')
    
  
  },


  {
    path: '/createGiftycard',
    name: 'createGiftycard',
    meta: {
        auth: true,
        title: 'Create a New Giftycard',
        description : ''
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/CreateEvent.vue')
    
  
  },

  {
    path: '/picpacks',
    name: 'Picpacks',
    meta: {
        auth: true,
        title: 'Your PicPacks',
        description : ''
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/Events.vue')
    
  
  },


  {
    path: '/giftycards',
    name: 'Giftycards',
    meta: {
        auth: true,
        title: 'Your Giftycards',
        description : ''
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/Events.vue')
    
  
  },


  {
    path: '/articles',
    name: 'Articles',
    meta: {
       
        title: 'Articles',
        description : ''
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/Articles.vue')
    
  
  },


  {
    path: '/giftycard-pricing',
    name: 'Keeping the Lights On',
    meta: {
       
        title: 'Articles',
        description : ''
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/Lights.vue')
    
  
  },


  {
    path: '/giftcards',
    name: 'Giftcards',
    meta: {
       
        title: 'Gift Cards',
        description : ''
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/Giftcards.vue')
    
  
  },


  {
    path: '/404',
    name: '404',
    meta: {
       
        title: '404',
        description : ''
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/404.vue')
    
  
  },



  {
    path: '/card/:cardID',
    name: 'Cards',
    meta: {
        auth: true,
        title: 'Live',
        description : ''
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/Landing.vue')
    
  
  },


  {
    path: '/packs/:packID',
    name: 'Packs',
    meta: {
        auth: true,
        title: 'Live',
        description : ''
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/PackLanding.vue')
    
  
  },
  
  


 


  {
    path: '/live/:eventID',
    name: 'Live',
    meta: {
        auth: true,
        title: 'Live',
        description : ''
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/Live.vue')
    
  
  },


  {
    path: '/review/:eventID',
    name: 'Manage',
    meta: {
        auth: true,
        title: 'Review',
        description : ''
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/Manage.vue')
    
  
  },


  {
    path: '/flyer/:eventID',
    name: 'Flyer',
    meta: {
        auth: true,
        title: 'Manage PicPack',
        description : ''
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/Flyer.vue')
    
  
  },

  {
    path: '/:eventID',
    name: 'PicPack',
   // meta: {
       // auth: true,
       // title: 'PicPack - Collect and share photos and videos with your group',
       // description : ''
     // },
    component: () => import(/* webpackChunkName: "about" */ '../views/Event.vue')
    
  
  },


  



  
]

const router = new VueRouter({
  mode: 'history',
  routes
})



export default router
